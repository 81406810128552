<template>
  <div class="certificate-student">
    <img
      class="certificate-student-img"
      src="./../../assets/logo.jpeg"
      alt=""
    />
    <div class="certificate-student-title">学员证书查询</div>
    <div class="certificate-student-form">
      <van-cell-group style="margin: 24px 0">
        <van-field
          v-model="certificateNo"
          label="证书编码"
          placeholder="请输入证书编码"
        />
        <van-field v-model="name" label="姓名" placeholder="请输入姓名" />
      </van-cell-group>
      <van-button type="info" block @click="fetchCheckCertificateNo()"
        >查 询</van-button
      >
    </div>
    <van-popup v-model="show"
      ><img :src="imageUrl" style="width: 350px"
    /></van-popup>
  </div>
</template>
<script>
import { Toast } from "vant";
import { checkCertificateNo } from "../../api/home";
export default {
  name: "CertificateStudent",
  data() {
    return {
      certificateNo: "",
      name: "",
      show: false,
      imageUrl: "",
    };
  },
  methods: {
    async fetchCheckCertificateNo() {
      if (this.certificateNo === "") {
        Toast("请输入证书编码");
        return;
      }
      if (this.name === "") {
        Toast("请输入姓名");
        return;
      }
      const result = await checkCertificateNo({
        certificateNo: this.certificateNo,
        name: this.name,
        memberType: "1",
      });
      if (result.code === "0" && result.data === "1") {
        this.show = true;
        this.imageUrl = `https://whxh.yingcaifuwu.top/api/certificateStudent/card?no=${this.certificateNo}`;
      } else {
        Toast("证书编码不正确");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.certificate-student {
  padding: 0 8px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.certificate-student-title {
  margin-top: 24px;
  font-size: 28px;
  font-weight: 500;
  color: #333;
}
.certificate-student-img {
  padding-top: 48px;
  width: 160px;
}
.certificate-student-form {
  width: 90%;
}
</style>
