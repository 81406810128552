<!--
 * @Author: dengjianwen deng_jwen@163.com
 * @Date: 2023-06-27 04:57:39
 * @LastEditors: dengjianwen deng_jwen@163.com
 * @LastEditTime: 2023-06-27 05:12:06
 * @FilePath: /yingcai-culture-h5/src/views/Article/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="home-group" v-for="item in groupList" :key="item.id">
      <p class="home-group-title">{{ item.articleType }}</p>
      <a
        class="home-group-article"
        v-for="itemChild in item.articleList"
        :key="itemChild.id"
        :href="`/article?id=${itemChild.id}`"
      >
        <p>{{ itemChild.title }}</p>
        <p class="home-group-article-time">{{ itemChild.createdTime }}</p>
      </a>
    </div>
  </div>
</template>

<script>
// import { Swipe, SwipeItem } from 'vant';
import { getArticleGroupList } from "./../../api/home.js";
export default {
  name: "ArticlePage",
  data() {
    return {
      groupList: [],
    };
  },
  mounted() {
    this.fetchGetArticleDetail();
  },
  methods: {
    async fetchGetArticleDetail() {
      const result = await getArticleGroupList({
        id: this.$route.query.id,
      });
      if (result.code === "0") {
        this.groupList = result.data;
      }
    },
  },
};
</script>
<style lang="less">
.home-group {
  padding: 0 24px;
  > .home-group-title {
    // border-left: 4px solid #0d289b;
    // padding-left: 12px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  > .home-group-article {
    border-bottom: 1px dashed rgba(13, 40, 155, 0.2);
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 4px;
    margin-bottom: 8px;
    display: block;
    color: #333;
    > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 0;
      margin-bottom: 4px;
      font-size: 15px;
    }
    > .home-group-article-time {
      color: #ccc;
      font-size: 12px;
    }
  }
}
</style>
